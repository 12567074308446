let toggleBtn = document.querySelector('.c-nav__toggle-btn');
let navList = document.querySelector('.c-nav__list');
let navContainer = document.querySelector('.c-nav__container');
let dropContainer = document.querySelectorAll('.c-nav__drop-container');
let dropList = document.querySelectorAll('.c-nav__drop-list');
let dropLink = document.querySelectorAll('.c-nav__link_drop');
var checkStatus = 0;

if (window.matchMedia("(max-width: 1023px)").matches) {
  checkStatus = 1;
}
toggleBtn.addEventListener('click', function () {
  if (checkStatus === 1) {
    navList.classList.toggle('c-nav__list_active');

    dropLink.forEach((item, index) => {
      dropLink[index].addEventListener('click', function () {
        dropList[index].classList.toggle('c-nav__drop-list_active');
        dropContainer[index].classList.toggle('c-nav__drop-container_active')
      })
    });
  }
})

if (window.matchMedia("(min-width: 1024px)").matches) {
  navContainer.style.overflow = "visible";
} else {
  navContainer.style.overflow = "hidden";
}


window.onresize = () => {
  
    
      if (window.matchMedia("(max-width: 1023px)").matches) {
        checkStatus = 1;
      }
      else{
        checkStatus = 0;
      }

      if (window.matchMedia("(min-width: 1024px)").matches) {
        navContainer.style.overflow = "visible";
      } else {
        navContainer.style.overflow = "hidden";
      }
      
  }  


